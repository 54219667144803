const CourseSettingsMenu = [
  {
    heading: "menu",
    route: "/training-settings",
    pages: [
      {
        heading: "Training Target",
        route: "/training-settings/training-target",
        fontIcon: "fas fa-university",
        svgIcon: "/media/icons/duotune/general/gen027.svg",
      },
      {
        heading: "Training Target List",
        route: "/training-settings/training-target-List",
        fontIcon: "fas fa-university",
        svgIcon: "/media/icons/duotune/general/gen027.svg",
      },

      {
        heading: "Assessment Type",
        route: "/training-settings/assessment-type",
        fontIcon: "bi-dashboard",
        svgIcon: "media/icons/duotune/general/gen025.svg",
      },
      {
        heading: "Industry Sectors",
        route: "/training-settings/industry-sectors",
        fontIcon: "bi-dashboard",
        svgIcon: "/media/icons/duotune/general/gen027.svg",
      },
      {
        heading: "Industry Sub Sectors",
        route: "/training-settings/industry-sub-sectors",
        fontIcon: "bi-dashboard",
        svgIcon: "/media/icons/duotune/general/gen027.svg",
      },
      {
        heading: "Training Milestone",
        route: "/training-settings/training-milestone",
        fontIcon: "bi-dashboard",
        svgIcon: "/media/icons/duotune/general/gen027.svg",
      },
    ],
  },
];

export default CourseSettingsMenu;
